<template>
    <Toast position="top-right" id="toast-notification" :closable="false" zIndex="9999" />
</template>

<script>
import Toast from 'primevue/toast'

export default {
    name: "NotificationToast",
    components: {
        Toast,
    },
}
</script>

<style lang="scss">
#toast-notification {
    opacity: 1;
    width: min(350px, calc(100% - 40px));

    .p-toast-message {
        border: none;
        position: relative;
        overflow: hidden;

        .p-toast-message-content {
            padding: 0;

            .p-toast-icon-close {
                position: absolute;
                top: 0px;
                right: 0px;
            }

            .p-toast-message-icon {
                display: none;
            }

            .p-toast-message-text {
                padding: 15px 25px 15px 35px;
                margin: 0px;

                .p-toast-summary {}

                .p-toast-detail {
                    display: none;
                }
            }
        }

        &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border: 15px solid transparent;
            border-top: 15px solid #fff2;
            top: 0;
            left: -15px;

        }

        &:after {
            content: ".";
            font-family: 'primeicons';
            position: absolute;
            top: 7px;
            left: 7px;
        }


        // COLORS
        &.p-toast-message-success {
            //background-color: #009563;
            background-image: linear-gradient(215deg, #009563, darken(adjust-hue(#009563, -10), 10));
            color: #8cffd7;

            &:after {
                content: "\E909"; // pi-check icon
            }
        }

        &.p-toast-message-info {
            //background-color: #516cbd;
            background-image: linear-gradient(215deg, #516cbd, darken(adjust-hue(#516cbd, -10), 10));
            color: #c9d6fc;

            &:after {
                content: "\E989"; // pi-exclamation-circle icon
            }
        }

        &.p-toast-message-error {
            //background-color: #931b33;
            background-image: linear-gradient(215deg, #931b33, darken(adjust-hue(#931b33, -10), 10));
            color: #ffc4d0;

            &:after {
                content: "\E922"; // pi-exclamation-triangle
            }
        }

        &.p-toast-message-warn {
            //background-color: #e1c032;
            background-image: linear-gradient(215deg, #e1c032, darken(adjust-hue(#e1c032, -10), 10));
            color: #725c02;

            &:after {
                content: "\E922"; // pi-exclamation-triangle
            }
        }
    }

}
</style>