<template>
    <div :class="containerClass" :data-theme="state.colorScheme" @click="onDocumentClick($event)">
        <div class="layout-content-wrapper">
            <AppTopBar :layoutMode="state.layoutMode" :topbarTheme="topbarTheme" :menuActive="state.menuActive"
                :mobileMenuActive="state.staticMenuMobileActive" :sidebarActive="state.sidebarActive"
                :sidebarStatic="state.sidebarStatic" :topbarUserMenuActive="state.topbarUserMenuActive"
                :topbarUserMenuClick="state.topbarUserMenuClick" :searchActive="state.searchActive"
                :searchClick="state.searchClick" :topbarItemClick="state.topbarItemClick"
                :activeTopbarItem="state.activeTopbarItem" @menu-click="state.onMenuClick"
                @menuitem-click="state.onMenuItemClick" @root-menuitem-click="state.onRootMenuItemClick"
                @menu-button-click="onMenuButtonClick" @right-menubutton-click="onRightMenuButtonClick"
                @toggle-menu="onToggleMenu" @topbar-usermenu-click="onTopbarUserMenuClick"
                @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver"
                @topbar-search-toggle="onTopbarSearchToggle" @topbar-search-click="onTopbarSearchClick"
                @topbar-search-hide="onTopbarSearchHide"></AppTopBar>

            <div class="layout-main">
                <div class="layout-content">
                    <!-- <PageHeader /> -->
                    <router-view />
                </div>
                <!-- <AppFooter /> -->
            </div>
        </div>

        <AppRightPanel v-model:rightMenuClick="state.rightMenuClick" :rightMenuActive="state.rightMenuActive"
            @right-menu-click="onRightMenuClick"></AppRightPanel>

        <!-- <AppConfig :layoutMode="state.layoutMode" :menuTheme="menuTheme" v-model:colorScheme="state.colorScheme"
            :topbarTheme="topbarTheme" v-model:configActive="state.configActive"
            @change-layout-mode="onChangeLayoutMode" @config-click="onConfigClick"
            @config-button-click="onConfigButtonClick" @change-color-scheme="changeColorScheme"
            @change-component-theme="changeComponentTheme" @topbar-theme="onTopbarThemeChange"
            @menu-theme="onMenuThemeChange"></AppConfig> -->

        <div class="layout-mask modal-in"></div>

        <NotificationToast />
    </div>
</template>

<script>
import { DomHandler } from 'primevue/utils'
import EventBus from './event-bus'
import AppTopBar from "./AppTopbar"
// import AppFooter from "./AppFooter"
// import AppConfig from "./AppConfig"
import AppRightPanel from "./AppRightPanel"
import NotificationToast from "@/components/NotificationToast"
import { reactive, computed, watch, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useNotificationToast from '../src/composables/useNotificationToast'
import { useStore } from 'vuex'
export default {

    props: {
        topbarTheme: String,
        menuTheme: String
    },

    emits: ["change-color-scheme", "change-component-theme", "topbar-theme", "menu-theme"],

    components: {
        AppTopBar,
        // AppFooter,
        // AppConfig,
        AppRightPanel,
        NotificationToast
    },

    setup(props, context) {
        const state = reactive({
            menuActive: false,
            layoutMode: "sidebar",
            colorScheme: "light",
            sidebarActive: false,
            sidebarStatic: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            topbarItemClick: false,
            activeTopbarItem: null,
            userMenuClick: false,
            topbarUserMenuActive: false,
            topbarUserMenuClick: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false
        })

        const vm = getCurrentInstance()

        const containerClass = computed(() => {
            return [
                "layout-wrapper",
                {
                    "layout-sidebar": state.layoutMode === "sidebar",
                    "layout-static": state.layoutMode === "sidebar" && state.sidebarStatic,
                    "layout-horizontal": state.layoutMode === "horizontal",
                    "layout-slim": state.layoutMode === "slim",
                    "layout-rightpanel-active": state.rightMenuActive,
                    'layout-mobile-active': state.staticMenuMobileActive,
                    "p-input-filled": vm.appContext.config.globalProperties.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !vm.appContext.config.globalProperties.$primevue.config.ripple,
                },
                "layout-menu-" + props.menuTheme + ' layout-topbar-' + props.topbarTheme
            ]
        })

        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const { toast, success, error, info, warn } = useNotificationToast()

        watch(route, async () => {
            state.menuActive = false
            toast.removeAllGroups()
        })

        onMounted(() => {

            document.addEventListener("hideMenu", () => {
                hideOverlayMenu()
            })

            document.addEventListener("visibilitychange", () => {
                if (document.visibilityState == "visible") {
                    // quando a aba estiver ativa
                    redirectIfTokenExpired()
                }
            })

            window.addEventListener("focus", () => {
                redirectIfTokenExpired()
            })

            // Eventos para chamar toast do useNotificationToast atraves do serviceWorker
            document.addEventListener('sendToast', (data) => {
                switch (data.detail.type) {
                    case 'success': success(data.detail.message); break
                    case 'error': error(data.detail.message); break
                    case 'info': info(data.detail.message); break
                    case 'warn': warn(data.detail.message); break
                    default: info(data.detail.message); break
                }
            })

        })

        function redirectIfTokenExpired() {

            if (new Date(store.state.expires_at) <= new Date) {
                store.dispatch('setLogged', {
                    isLogged: false
                })

                router.push({ name: 'Login', query: { 'expired': true } })
            }

        }

        function onDocumentClick() {
            if (!state.searchClick && state.searchActive) {
                onTopbarSearchHide()
            }

            if (!state.topbarUserMenuClick && state.topbarUserMenuActive) {
                state.topbarUserMenuActive = false
                state.topbarUserMenuClick = false
            }

            if (!state.rightMenuClick) {
                state.rightMenuActive = false
            }

            if (!state.menuClick) {
                if (isSlim() || isHorizontal()) {
                    EventBus.emit('reset-active-index')
                    state.menuActive = false
                }

                if (state.staticMenuMobileActive) {
                    // hideOverlayMenu()
                }

                unblockBodyScroll()
            }

            if (state.configActive && !state.configClick) {
                state.configActive = false
            }

            state.searchClick = false
            state.configClick = false
            state.userMenuClick = false
            state.rightMenuClick = false
            state.topbarUserMenuClick = false
            state.menuClick = false
        }

        function onToggleMenu(event) {
            state.menuClick = true
            state.sidebarStatic = !state.sidebarStatic

            event.preventDefault()
        }

        function onMenuClick(event) {
            if (isHorizontal() && DomHandler.hasClass(event.target, 'layout-menu-container')) {
                EventBus.emit('reset-active-index')
                state.menuClick = false
                state.menuActive = false
            }
            state.menuClick = true
        }

        function onMenuButtonClick(event) {
            state.menuClick = true
            state.topbarUserMenuActive = false
            state.rightMenuActive = false

            if (isMobile()) {
                state.staticMenuMobileActive = !state.staticMenuMobileActive
                if (state.staticMenuMobileActive) {
                    blockBodyScroll()
                } else {
                    unblockBodyScroll()
                }
            }

            event.preventDefault()
        }

        function onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index')
                hideOverlayMenu()
            }
            if (!event.item.items && isSlim()) {
                state.menuActive = false
            }
        }

        function onRootMenuItemClick() {
            state.menuActive = !state.menuActive
        }

        function onRightMenuClick() {
            state.rightMenuClick = true
        }

        function onRightMenuButtonClick(event) {
            state.rightMenuClick = true
            state.rightMenuActive = !state.rightMenuActive
            hideOverlayMenu()
            event.preventDefault()
        }

        function onConfigClick() {
            state.configClick = true
        }

        function onConfigButtonClick() {
            state.configActive = !state.configActive
            state.configClick = true
        }

        function hideOverlayMenu() {
            state.staticMenuMobileActive = false
            unblockBodyScroll()
        }

        function onChangeLayoutMode(mode) {
            state.layoutMode = mode
            if (mode === 'sidebar') {
                if (state.sidebarStatic) {
                    state.sidebarActive = true
                }
            }
            else {
                state.sidebarActive = false

                if (props.topbarTheme !== props.menuTheme) {
                    context.emit('menu-theme', props.topbarTheme)
                }
            }
        }

        function onTopbarThemeChange(theme) {
            context.emit('topbar-theme', theme)
        }

        function onMenuThemeChange(theme) {
            context.emit('menu-theme', theme)
        }

        function changeComponentTheme(theme) {
            context.emit('change-component-theme', theme)
        }

        function changeColorScheme(scheme) {
            state.colorScheme = scheme
            context.emit('change-color-scheme', scheme)
            vm.appContext.config.globalProperties.$appState.colorScheme = scheme
        }

        function onSidebarMouseOver() {
            state.sidebarActive = !isMobile()
        }

        function onSidebarMouseLeave() {
            state.sidebarActive = false
        }

        function onTopbarSearchToggle() {
            state.searchActive = !state.searchActive
            state.searchClick = true
        }

        function onTopbarSearchClick() {
            state.searchClick = true
        }

        function onTopbarSearchHide() {
            state.searchActive = false
            state.searchClick = false
        }

        function onTopbarUserMenuClick() {
            state.topbarUserMenuActive = !state.topbarUserMenuActive
            state.topbarUserMenuClick = true
        }

        function blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll')
            } else {
                document.body.className += ' blocked-scroll'
            }
        }

        function unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll')
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
            }
        }

        function isSlim() {
            return state.layoutMode === "slim"
        }

        function isHorizontal() {
            return state.layoutMode === "horizontal"
        }

        function isDesktop() {
            return window.innerWidth > 991
        }

        function isMobile() {
            return window.innerWidth <= 991
        }

        return {
            state,
            containerClass,
            redirectIfTokenExpired,
            onDocumentClick,
            onToggleMenu,
            onMenuClick,
            onMenuButtonClick,
            onMenuItemClick,
            onRootMenuItemClick,
            onRightMenuClick,
            onRightMenuButtonClick,
            onConfigClick,
            onConfigButtonClick,
            hideOverlayMenu,
            onChangeLayoutMode,
            onTopbarThemeChange,
            onMenuThemeChange,
            changeComponentTheme,
            changeColorScheme,
            onSidebarMouseOver,
            onSidebarMouseLeave,
            onTopbarSearchToggle,
            onTopbarSearchClick,
            onTopbarSearchHide,
            onTopbarUserMenuClick,
            blockBodyScroll,
            unblockBodyScroll,
            isSlim,
            isHorizontal,
            isDesktop,
            isMobile
        }
    }
}
</script>
