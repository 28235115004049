import { createApp, h, reactive } from 'vue'
import router from './router'
import AppWrapper from './AppWrapper.vue'
import PrimeVue from 'primevue/config'
import store from './store/store'


import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import Badge from 'primevue/badge'
import StyleClass from 'primevue/styleclass'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import RadioButton from 'primevue/radiobutton'
import InputSwitch from 'primevue/inputswitch'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import Chart from 'primevue/chart'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Breadcrumb from 'primevue/breadcrumb'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import Password from 'primevue/password'
import SplitButton from 'primevue/splitbutton'




import CodeHighlight from './AppCodeHighlight'
import BlockViewer from './BlockViewer'

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
import './App.scss'

const app = createApp({
    render () { return h(AppWrapper) }
})

app.config.globalProperties.$appState = reactive({ colorScheme: 'light', isNewThemeLoaded: false })

app.use(PrimeVue, { 
    ripple: true,
    locale: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Limpar',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Confirmar',
        reject: 'Rejeitar',
        choose: 'Selecione um arquivo',
        upload: 'Upload',
        cancel: 'Cancelar',
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        chooseYear: 'Escolhar o ano',
        chooseMonth: 'Escolha o mês',
        chooseDate: 'Escolha a data',
        today: 'Hoje',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
        dateFormat: 'mm/dd/yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Digite a senha',
        emptyFilterMessage: 'Nenhum resultado encontrado',
        emptyMessage: 'Nenhuma opção disponível'
    } 
})

app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(store)

app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('code', CodeHighlight)
app.directive('badge', BadgeDirective)
app.directive('styleclass', StyleClass)



app.component('Chart',Chart)
app.component('DataTable',DataTable)
app.component('Dialog',Dialog)
app.component('Column',Column)
app.component('Calendar',Calendar)
app.component('Dropdown',Dropdown)
app.component('MultiSelect',MultiSelect)
app.component('Breadcrumb',Breadcrumb)
app.component('ProgressBar',ProgressBar)
app.component('ProgressSpinner',ProgressSpinner)
app.component('RadioButton',RadioButton)
app.component('TabPanel',TabPanel)
app.component('TabView',TabView)
app.component('Button',Button)
app.component('InputSwitch',InputSwitch)
app.component('InputText',InputText)
app.component('InputMask',InputMask)
app.component('InputNumber',InputNumber)
app.component('Password',Password)
app.component('Badge',Badge)
app.component('BlockViewer', BlockViewer)
app.component('Toast', Toast)
app.component('SplitButton', SplitButton)


app.mount('#app')