export default (api, resource) => ({
    
    create: async ({user, rota, token}) => {
        let url = `${resource}/registrar`
        let authorization = token
        

        if(rota && rota === "admins"){
            url = `${url}/administrador`
            authorization = "11223344"
        }

        if(rota && rota === "gerenteSecao"){
            url = `${url}/section-admin`
        }

        if(rota && rota === "operadores"){
            url = `${url}/operador`
        }

        if(rota && rota === "estabelecimentos"){
            url = `${url}/pos`
        }

        const response = await api.post(url,{
            ...user
          }, {
            headers: {
                Authorization: authorization
            } 
        })
    
        return {
            data: response.data
        }
    },

    
    login: async ({login, password, admin }) => {
        let errors = null
        let response = null

        try {
            response = await api.post(`${resource}/entrar`, {
                login,
                senha: password,
                admin: admin
            })
        }
        catch(e){
            errors = {
                status: e.response?.status || 500,
                statusText: e.response?.statusText || "Internal Server Error",
                data: e.response?.data || "Erro desconhecido"
            }
        }
        
        return {
            data: response?.data || null,
            errors
        }
    },


    changePassword: async ({user,token}) => {
        const response = await api.put(`/${resource}/seguranca/alterar-senha`,{
            ...user
          }, {
            headers: {
                Authorization: token
            } 
        })

        return{
            data: response.data
        }
    },



  
  
})