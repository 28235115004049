export default (api, resource) => ({

    getAll: async({token,params}) =>{

        const response = await api.get(resource,{
            params,
            headers:{ 
                Authorization: token
            }
        })

        return{
            data: response.data
        }
    },

    create: async({establishments,token}) =>{
        const response = await api.post(resource,establishments,{
            headers:{
                Authorization: token
            }
        })

        return{
            data: response.data
        }
    },


    update: async({establishments,token}) =>{
        const response = await api.put(`${resource}/${establishments.id}`,establishments,{
            headers:{
                Authorization: token
            }
        })
        
        return{
            data: response.data
        }
    }
})