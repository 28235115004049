export default (api , resource) => ({
     
    getAll: async ({token,params}) =>{

        const response =  await api.get(resource,{
            params,
            headers:{
                Authorization : token
            }
        })

        return{
            response: response.data
        }
    }
})