<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <router-link id="logo-link" class="flex align-items-center" to="/">
                    <img src="https://dash.sorte.tv/img/logo-sorte-nova.png" alt="logo-sortetv" style="width: 40px; height: 30px;" />
                </router-link>
            </div>

            <AppMenu :layoutMode="layoutMode" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic"
                :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" @sidebar-mouse-leave="onSidebarMouseLeave"
                @sidebar-mouse-over="onSidebarMouseOver" @toggle-menu="onToggleMenu" @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <li ref="profile" class="topbar-item user-profile" :class="{ 'active-topmenuitem fadeInDown': topbarUserMenuActive }">
                        <div class="font-bold">{{ usuario.nome.toUpperCase() }}</div>
                    </li>
                </ul>

                <a href="#" tabindex="0" class="layout-rightpanel-button" @click="onRightMenuButtonClick($event)">
                    <i class="pi pi-user" style="font-size: 1.5rem"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import AppMenu from './AppMenu'
export default {
    name: "AppTopbar",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "toggle-menu", "right-menubutton-click",
        "sidebar-mouse-over", "sidebar-mouse-leave", "topbar-search-toggle", "topbar-search-click", "topbar-search-hide",
        "topbar-usermenu-click", "update:searchClick"],
    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },

    components: {AppMenu},

    setup(){

        const store = useStore()
        const usuario = computed(()=> store.getters.getUsuario)

        return{usuario}
    },


    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    },
    methods: {
        onMenuClick(event) {
            this.$emit("menu-click", event)
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event)
        },
        onRootMenuItemClick(event) {
            this.$emit("root-menuitem-click", event)
        },
        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event)
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event)
        },
        onTopbarSearchToggle(event) {
            this.$emit('topbar-search-toggle', event)
            this.onSearchFocus()
        },
        onTopbarSearchClick(event) {
            this.$emit("topbar-search-click", event)
        },
        onInputKeydown(event) {
            const key = event.which

            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                this.$emit("topbar-search-hide", event)
            }
        },
        onTopbarUserMenuClick(event) {
            this.$emit("topbar-usermenu-click", event)
        },
        onRightMenuButtonClick(event) {
            this.$emit("right-menubutton-click", event)
        },
        onSidebarMouseOver() {
            this.$emit("sidebar-mouse-over")
        },
        onSidebarMouseLeave() {
            this.$emit("sidebar-mouse-leave")
        },
        onSearchFocus() {
            if (window.innerWidth >= 576) {
                this.$refs.desktopInput.$el.focus()
            }
            else {
                this.$nextTick(function () {
                    this.$refs.phoneInput.$el.focus()
                })
            }
        },
    },

}
</script>