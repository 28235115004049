<template>
    <div class="exception-body access">
        <div class="exception-topbar">
            <img src="https://dash.sorte.tv/img/logo-sorte-nova.png" alt="logo-bingo" style="width: 50px; height: 40px;">
        </div>
        <div class="exception-wrapper">
            <div class="exception-content">
                <img src="layout/images/pages/asset-access.svg" alt="freya-layout" />
                <span>Acesso Negado</span>
            </div>
            <div class="exception-footer">
                <h6>Copyright Ⓒ 2024</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        colorScheme() {
            return this.$appState.colorScheme 
        }
    },
    methods: {
        goDashboard() {
            window.location = "/#/"
        }
    }
}
</script>
