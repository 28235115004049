export default (api, resource) => ({

    create: async ({partida, token}) => {

        const response = await api.post(resource, partida, {
            headers: {
                Authorization: token
            } 
        })

        return {
          data: response.data
        }       
    },

    update: async ({partida, token}) =>{
        const response = await api.put(`${resource}/${partida.id}`, partida,{
            headers:{
                Authorization: token
            }
        })

        return {
            data: response.data
        }
    },


    getAll: async({token, params}) =>{

        const response = await api.get(`${resource}/todas`,{
            params,
            headers:{
                Authorization: token
            },
        })

        return {
            data: response.data
        }
    },


    clone: async({token, params}) =>{
        const response = await api.post(`${resource}/clonar`,params,{
            headers:{
                Authorization: token
            }
        })

        return {
            data: response.data
        }
    }
})