<template>
    <div class="tela-de-catelas">

        <!--TÍTULO-->
        <div class="header  p-3">

            <div v-if="$route.name !== 'cartela-detalhes'" class="input-btn flex align-items-center justify-content-center flex-column mb-2">
                
                <span class="text-3xl font-bold">CARTELAS</span>
                
                <div class="p-inputgroup"> 
                    <InputText v-model="stateCartelas.pesquisarCartela" placeholder="Pesquisar cartela..."/>
                    <Button icon="pi pi-search" :disabled="!stateCartelas.pesquisarCartela" 
                    :loading="stateCartelas.loading.carregarCartela"  type="submit" @click="carregarCartelas()"/>
                </div>

            </div>


            <!--INFORMAÇÕES-->
            <div v-if="stateCartelas.cartelas.games && stateCartelas.loading.carregarCartela === false" class="informacoes mb-2 mx-5">
                <div>
                    <div class="text-lg">
                        <span class="font-semibold">Local: </span> 
                        <span class="font-bold">{{ stateCartelas.cartelas.establishment }}</span>
                    </div>

                    <div class="text-lg">
                        <span class="font-semibold">Data: </span> 
                        <span class="font-bold">{{ formatarData(stateCartelas.cartelas.date)}} </span>
                    </div>
                   
                </div>

                <div>
                    <div class="text-lg">
                        <span class="font-semibold">Valor da cartela: </span>  
                        <span class="font-bold">{{ formatarParaReais(stateCartelas.cartelas.games[0].paidValue)}}</span> 
                    </div>

                    <div class="text-lg">
                        <span class="font-medium">Quantidade de cartelas: </span> 
                        <span class="font-bold">{{stateCartelas.cartelas.games.length }}</span>
                    </div>
                </div>
                
                <div>
                    <div class="text-lg">
                        <span class="font-semibold">Doado: </span> 
                        <span class="font-bold">{{formatarParaReais(stateCartelas.cartelas.games.length * stateCartelas.cartelas.games[0].paidValue)}}</span>
                    </div>

                    <div class="text-lg">
                        <span class="font-semibold">Sorteio: </span> 
                        <span class="font-bold">{{ stateCartelas.cartelas.id }}</span> 
                    </div>
                </div>
            </div>

        </div>
            
            
        <!--CARTELA-->
        <div>
            <ProgressSpinner v-if="stateCartelas.loading.carregarCartela" class="flex justify-content-center mt-8"></ProgressSpinner>

            <ul class="cartelas" v-else>
                <li v-for="game in stateCartelas.cartelas.games" :key="game.id" style="list-style-type: none;" class="cartela shadow-2 flex gap-4">
                    <div class="cartela-header text-2xl text-center">
                        CARTELA: <span class="font-bold ">{{ game.id }}</span>
                    </div>

                    <div class="ordem-numeros">
                        <div class="numero-cartela" v-for="ball in game.ballOfGames" :key="ball.id">{{ ball.number }}</div>
                    </div>
                </li>

                
                <li v-if="stateCartelas.errorMessage" class="text-center text-red-500" style="list-style-type: none;">
                    <span class="text-5xl font-bold">
                        {{stateCartelas.errorMessage}}
                    </span>
                </li>
            </ul>
        </div>
        
       
    </div>
</template>

<script>
import moment from 'moment'
import { useRoute } from 'vue-router'
import useCartelas from '../../composables/useCartelas'

export default {

    setup(){
        
        const route = useRoute()

        //composables
        const {stateCartelas,carregarCartelas} = useCartelas()


        //pega o token e coloac na rota
        if(route.params.token){
            stateCartelas.pesquisarCartela = route.params.token    
        }
        

        //loads
        if(route.name === 'cartela-detalhes'){
            carregarCartelas()
        }


        //formata para reais
        function formatarParaReais(valor) {
            return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }


        //formata a data
        function formatarData(data){
            return moment(data, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")
        }
    
        

        return{
            stateCartelas,
            formatarParaReais,
            carregarCartelas,
            formatarData,
        }
    }
}
</script>


<style scoped>
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ededed;
}

.ordem-numeros {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;
}

.numero-cartela {
    border: 1px solid black;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    width: 60px;
    height: 60px;
}

.cartela-header{
    background-color: #ededed;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.cartela {
    width: 350px; 
    min-height: 300px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #ffff;
}

.cartelas{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.informacoes{
    display: flex;
    background-color: #ffff;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    gap: 40px;
}

@media screen and (max-width: 800px) {
    .informacoes{
        flex-direction: column;
        gap:0
    }

    .cartelas{
       flex-direction: column
    }
}
</style>