<template>
    <div class="exception-body notfound">
        <div class="exception-wrapper">
            <div class="exception-content">
                <img src="layout/images/pages/asset-404.svg" alt="freya-layout" />
                <span>404</span>
                <span class="exception-subtitle">Página não encontrada.</span>
                <Button type="button" label="VOLTAR" @click="goDashboard" ></button>
            </div>
            <div class="exception-footer mt-4">
                <h6>Copyright Ⓒ 2024</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        colorScheme() {
            return this.$appState.colorScheme 
        }
    },
    methods: {
        goDashboard() {
            window.location = "/#/"
        }
    }
}
</script>